import React from 'react';

const navItem = (props) => {
    const classNames = ["nav-item"];

    if(props.active) {
        classNames.push("active");
    }

    return (
        <li className={classNames.join(' ')}>
            <a className="nav-link" href={props.link} onClick={(e) => props.onNavClick(e, props.name)}>
                {props.name}
            </a>
        </li>
    );
}

export default navItem;