import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/esm/locale';

import * as contactPageTypes from '../../enums/contactPageTypes';
import api from '../../api/api';

import 'react-datepicker/dist/react-datepicker.css';
import './ContactPage.scss';

const ContactPage = (props) => {
    const [showIframe] = useState(true);
    const [sending, setSending] = useState('INIT');
    const [entityType, setEntityType] = useState('0');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [question, setQuestion] = useState('');
    const [address, setAddress] = useState('');
    const [location, setLocation] = useState('');
    const [description, setDescription] = useState('');
    const [delivery, setDelivery] = useState('');
    const [budget, setBudget] = useState('');
    const [errors, setErrors] = useState([]);

    /*useEffect(() => {
        setShowIframe(true);
    }, [])*/

    /************************************************************************
     * Event handlers
     ***********************************************************************/

    const handleSendClicked = (e) => {
        e.preventDefault();

        const errors = [];

        // Validation of the general required fields
        if (entityType === '0') {
            errors.push('entityType');
        }
        if (name.trim() === '') {
            errors.push('name');
        }
        if (phoneNumber.trim() === '') {
            errors.push('tel');
        }
        if (email.trim() === '') {
            errors.push('email');
        } else if (!validateEmail(email)) {
            errors.push('email');
        }

        // Validation for the contact specific fields
        if (props.type === contactPageTypes.CONTACT) {
            if (question.trim() === '') {
                errors.push('question');
            }
        }

        // Update the array of errors
        setErrors(errors);

        // When there are errors we won't save
        if (errors.length > 0) {
            return;
        }

        // Set the sending state of the email to sending
        setSending('SENDING');

        // Send the email via the api
        if (props.type === contactPageTypes.CONTACT) {
            api.post('email/basic', {
                companyOrNot: entityType,
                name: name,
                phoneNumber: phoneNumber,
                email: email,
                message: question,
            })
                .then((resp) => {
                    setSending('SUCCESS');
                })
                .catch((err) => console.log(err));
        } else {
            const deliveryValue = convertDeliveryIdToValue(delivery);

            api.post('email/quotation', {
                companyOrNot: entityType,
                name: name,
                phoneNumber: phoneNumber,
                email: email,
                address: address,
                eventDate: props.contact.date,
                location: location,
                description: description,
                delivery: deliveryValue,
                budget: budget,
            })
                .then((resp) => {
                    setSending('SUCCESS');
                })
                .catch((err) => console.log(err));
        }
    };

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function convertDeliveryIdToValue(delivery) {
        switch (delivery) {
            case '0':
                return 'Later te bepalen';
            case '1':
                return 'Leveren';
            case '2':
                return 'Leveren met opstellen';
            case '3':
                return 'Zelf ophalen';
            default:
                return 'Later te bepalen';
        }
    }

    /************************************************************************
     * Render
     ***********************************************************************/
    let value = '0';
    let questionContext = '';
    let iframe = '';
    let context = '';

    if (sending === 'SENDING') {
        context = (
            <div className='alert alert-primary' role='alert'>
                <i className='fas fa-circle-notch fa-spin mr-3' /> Bedankt voor je bericht. Nog even geduld terwijl het verzonden wordt.
            </div>
        );
    } else if (sending === 'SUCCESS') {
        context = (
            <div className='alert alert-success' role='alert'>
                <i className='fas fa-check mr-3' /> Bericht is verzonden. We sturen je zo snel mogelijk een antwoord.
            </div>
        );
    } else {
        if (props.type === contactPageTypes.CONTACT) {
            value = '1';

            const questionClassname = ['form-control'];

            if (errors.indexOf('question') !== -1) {
                questionClassname.push('is-invalid');
            }

            // Normal Contact page content
            questionContext = (
                <div className='form-group row'>
                    <label htmlFor='description' className='col-sm-3 col-form-label'>
                        Uw Vraag
                    </label>
                    <div className='col-sm-9 col-lg-8'>
                        <textarea
                            id='description'
                            rows='8'
                            value={question}
                            className={questionClassname.join(' ')}
                            placeholder='Gelieve uw vraag hier te formuleren'
                            onChange={(e) => setQuestion(e.target.value)}></textarea>
                        {errors.indexOf('question') !== -1 ? (
                            <div id='validationNameFeedback' className='invalid-feedback'>
                                Vergeet geen bericht na te laten aub.
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            );
        } else if (props.type === contactPageTypes.QUOTATION) {
            value = '2';

            // Content for when the customer asks for a quotation
            questionContext = (
                <>
                    <div className='form-group row'>
                        <label htmlFor='address' className='col-sm-3 col-form-label'>
                            Adres
                        </label>
                        <div className='col-sm-9 col-lg-8'>
                            <input
                                type='text'
                                className='form-control'
                                id='address'
                                value={address}
                                placeholder='Gelieve uw adres in te vullen (niet verplicht)'
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label htmlFor='date' className='col-sm-3 col-form-label'>
                            Datum
                        </label>
                        <div className='col-sm-9 col-lg-8'>
                            <DatePicker
                                dateFormat='dd/MM/yyyy'
                                locale={nl}
                                className='react-datepicker-ignore-onclickoutside form-control'
                                selected={props.contact.date}
                                id='date'
                                onChange={(date) => props.onDateChange(date)}
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label htmlFor='location' className='col-sm-3 col-form-label'>
                            Locatie
                        </label>
                        <div className='col-sm-9 col-lg-8'>
                            <input
                                type='text'
                                className='form-control'
                                id='location'
                                value={location}
                                placeholder='Gelieve de locatie waar het evenement plaats vindt in te vullen (niet verplicht)'
                                onChange={(e) => setLocation(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label htmlFor='description' className='col-sm-3 col-form-label'>
                            Omschrijving
                        </label>
                        <div className='col-sm-9 col-lg-8'>
                            <textarea
                                id='description'
                                className='form-control'
                                value={description}
                                rows='4'
                                placeholder='Gelieve een korte omschrijving van het evenement te voorzien'
                                onChange={(e) => setDescription(e.target.value)}></textarea>
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label htmlFor='delivery' className='col-sm-3 col-form-label'>
                            Levering
                        </label>
                        <div className='col-sm-9 col-lg-8'>
                            <select id='delivery' className='form-control' value={delivery} onChange={(e) => setDelivery(e.target.value)}>
                                <option value='0'>Later te bepalen</option>
                                <option value='1'>Leveren</option>
                                <option value='2'>Leveren met opstellen</option>
                                <option value='3'>Zelf ophalen</option>
                            </select>
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label htmlFor='budget' className='col-sm-3 col-form-label'>
                            Budget
                        </label>
                        <div className='col-sm-9 col-lg-8'>
                            <input
                                type='number'
                                className='form-control'
                                id='budget'
                                value={budget}
                                placeholder='Gelieve een raming voor jullie budget op te geven (niet verplicht)'
                                onChange={(e) => setBudget(e.target.value)}
                            />
                        </div>
                    </div>
                </>
            );
        }

        const entityTypeClassName = ['form-control'];
        const nameClassName = ['form-control'];
        const telClassName = ['form-control'];
        const emailClassName = ['form-control'];

        if (errors.indexOf('name') !== -1) {
            nameClassName.push('is-invalid');
        }
        if (errors.indexOf('entityType') !== -1) {
            entityTypeClassName.push('is-invalid');
        }
        if (errors.indexOf('tel') !== -1) {
            telClassName.push('is-invalid');
        }
        if (errors.indexOf('email') !== -1) {
            emailClassName.push('is-invalid');
        }

        context = (
            <form>
                <div className='form-group row'>
                    <label htmlFor='contactType' className='col-sm-3 col-form-label'>
                        Type *
                    </label>
                    <div className='col-sm-9 col-lg-8'>
                        <select id='contactType' className='form-control' value={value} onChange={props.onTypeChange}>
                            <option value='0' disabled>
                                Kies uw type vraag
                            </option>
                            <option value='1'>Vraag</option>
                            <option value='2'>Offerte-aanvraag</option>
                        </select>
                    </div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='customerType' className='col-sm-3 col-form-label'>
                        Particulier of bedrijf *
                    </label>
                    <div className='col-sm-9 col-lg-8'>
                        <select id='customerType' value={entityType} className={entityTypeClassName.join(' ')} defaultValue='0' required onChange={(e) => setEntityType(e.target.value)}>
                            <option value='0' disabled>
                                Gelieve uw keuze te maken
                            </option>
                            <option value='Particulier'>Particulier</option>
                            <option value='Bedrijf'>Bedrijf</option>
                        </select>
                        {errors.indexOf('entityType') !== -1 ? (
                            <div id='validationNameFeedback' className='invalid-feedback'>
                                Gelieve een keuze te maken
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='name' className='col-sm-3 col-form-label' required>
                        Naam *
                    </label>
                    <div className='col-sm-9 col-lg-8'>
                        <input type='text' value={name} className={nameClassName.join(' ')} id='name' placeholder='Gelieve uw naam in te vullen.' onChange={(e) => setName(e.target.value)} />
                        {errors.indexOf('name') !== -1 ? (
                            <div id='validationNameFeedback' className='invalid-feedback'>
                                Gelieve uw naam in te vullen
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='phone' className='col-sm-3 col-form-label' required>
                        Tel *
                    </label>
                    <div className='col-sm-9 col-lg-8'>
                        <input
                            type='tel'
                            value={phoneNumber}
                            className={telClassName.join(' ')}
                            id='phone'
                            placeholder='Gelieve uw telefoonnummer in te vullen.'
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        {errors.indexOf('tel') !== -1 ? (
                            <div id='validationNameFeedback' className='invalid-feedback'>
                                Gelieve een telefoonnummer in te vullen
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='email' className='col-sm-3 col-form-label' required>
                        Email *
                    </label>
                    <div className='col-sm-9 col-lg-8'>
                        <input
                            type='email'
                            value={email}
                            className={emailClassName.join(' ')}
                            id='email'
                            placeholder='Gelieve uw emailadres in te vullen.'
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {errors.indexOf('email') !== -1 ? (
                            <div id='validationNameFeedback' className='invalid-feedback'>
                                Gelieve een geldig mailadres in te vullen
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>

                {questionContext}

                <div className='form-group row'>
                    <div className='col-sm-12 col-lg-11'>
                        <button type='submit' className='btn btn-light float-right' onClick={handleSendClicked}>
                            Versturen
                        </button>
                    </div>
                </div>
            </form>
        );
    }

    // Show the iframwe with the canvas
    if (showIframe) {
        iframe = (
            <iframe
                id='gmap_canvas'
                title='google_maps_canvas'
                src='https://maps.google.com/maps?q=panda%20sound%20%26%20light&t=&z=15&ie=UTF8&iwloc=&output=embed'
                frameBorder='0'
                scrolling='no'
                marginHeight='0'
                marginWidth='0'></iframe>
        );
    }

    return (
        /* General contact information (display) */
        <div className='component contact d-flex justify-content-center' ref={props.reference}>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='block-title'>
                        <div className='line-before d-none d-md-table-cell'> </div>
                        <h1 style={{ width: '150px' }}>
                            <span>Contacteer ons</span>
                        </h1>
                        <div className='line-after d-none d-md-table-cell'> </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <div className='row py-2'>
                            <div className='offset-0 offset-md-1 col-3 col-md-2'>Tel:</div>
                            <div className='col-9'>
                                <a href='tel:0498647166'>0498/65.71.66</a>
                            </div>
                        </div>
                        <div className='row py-2'>
                            <div className='offset-0 offset-md-1 col-3 col-md-2'>Email:</div>
                            <div className='col-9'>
                                <a href='mailto:info@pandasound.be'>info@pandasound.be</a>
                            </div>
                        </div>
                        <div className='row py-2'>
                            <div className='offset-0 offset-md-1 col-3 col-md-2'>Adres:</div>
                            <div className='col-9'>
                                <a href='https://www.google.com/maps/dir/?api=1&amp;destination=Panda+Sound+&amp;+Light' target='_blank' rel='noopener noreferrer'>
                                    Hovenierstraat 36, 9940 Evergem
                                </a>
                            </div>
                        </div>
                        <div className='row py-2'>
                            <div className='offset-0 offset-md-1 col-3 col-md-2'>Sociale media:</div>
                            <div className='col-9'>
                                <a href='https://www.facebook.com/Panda-Sound-Light-212933912146380/' target='_blank' rel='noopener noreferrer' className='pr-3'>
                                    <i className='fab fa-facebook-f'></i>
                                </a>
                                <a
                                    href='https://www.google.be/search?ei=ajeOXMvTFcH4wALLja3ICg&q=panda+sound+%26+light&oq=panda+sound+%26+light&gs_l=psy-ab.3..38l2.7396.7536..7712...0.0..0.56.105.2......0....1..gws-wiz.......0i71.y1ZMdB4yrZc#'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    <i className='fab fa-google'></i>
                                </a>
                            </div>
                        </div>
                        <div className='row pt-4 pb-4 pb-md-0' id='map'>
                            <div className='mapouter h-100 offset-0 offset-md-1 col-12 col-md-11'>
                                <div className='gmap_canvas h-100'>{iframe}</div>
                            </div>
                        </div>
                    </div>

                    {/* Contact questions for every contact type */}
                    <div className='col-xs-12 col-md-6'>{context}</div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
