import React from 'react'

import Logo from '../../assets/images/Panda-Sound-Light.webp';
import './HomePage.scss'

const homePage = (props) => {
    return(
        <div className="home component" ref={props.reference}>
            <div className="container d-flex justify-content-center">
                <div className="logo-container d-none d-md-block pt-1">
                    <img className="img-fluid" src={Logo} alt="panda sound &amp; light logo" />
                </div>

                <div className="align-self-center">
                    <h1 className="text-white">Panda Sound &amp; Light</h1>
                    <div className="row">
                        <div className="col-12">
                            <span className="text-white">
                                {/*Verhuur van licht, geluid en video installaties voor elk evenement. We werken momenteel aan een nieuwe website, we verwelkomen je binnenkort graag terug!*/}
                                Verhuur van licht, geluid en video installaties voor elk evenement. Dankzij onze professionele aanpak heeft uw evenement er nog nooit zo goed uit gezien.
                            </span>
                        </div>
                        <div className="col-12 mt-3">
                            <button type="button" className="btn btn-outline-light" onClick={props.onServiceClick}>Onze diensten</button>
                            <button type="button" className="btn btn-light ml-4" onClick={props.onInvoiceClick}>Offerte aanvragen</button>
                            {/*<a href="mailto:info@pandasound.be" className="btn btn-light" style={{marginTop: '20px', marginRight:'20px'}}>info@pandasound.be</a>
                            <a href="tel:0498647166" className="btn btn-outline-light" style={{marginTop: '20px', marginRight:'20px'}}>0498/64.71.66</a>
                            <a href="#" className="btn btn-outline-light" style={{marginTop: '20px'}}>Poperingestraat 2, 9000 Gent</a>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default homePage;