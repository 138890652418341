import React from 'react';

const Realisations = ({ img, srcSet, sizes, name, description, width, height }) => {
    return (
        <div className='col-xs-12 col-md-3 mb-2 mb-md-0'>
            <div className='realisation'>
                <img src={img} srcSet={srcSet} sizes={sizes} width={width} height={height} className='img-fluid' alt={name} />
                <div className='mask'>
                    <h2>{name}</h2>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
};

export default Realisations;
