import React, { Component } from 'react';

import * as contactPageTypes from '../enums/contactPageTypes';

import Navigation from '../components/Navigation/Navigation';
import HomePage from '../components/Pages/HomePage';
import ServicesPage from '../components/Pages/ServicesPage';
import RealisationsPage from '../components/Pages/RealisationsPage';
import ContactPage from '../components/Pages/ContactPage';

class App extends Component {
    state = {
        navItems: [
            {
                id: 1,
                name: 'Home',
                link: '/',
                active: true,
            },
            {
                id: 2,
                name: 'Onze diensten',
                link: '/diensten',
                active: false,
            },
            {
                id: 3,
                name: 'Realisaties',
                link: '/realisaties',
                active: false,
            },
            {
                id: 4,
                name: 'Contact',
                link: '/contact',
                active: false,
            },
        ],
        contactPageType: contactPageTypes.CONTACT,
        contact: {
            date: new Date(),
        },
    };

    constructor(props) {
        super(props);
        this.homePageRef = React.createRef();
        this.servicesPageRef = React.createRef();
        this.realisationsPageRef = React.createRef();
        this.contactPageRef = React.createRef();
    }

    componentDidMount = () => {
        window.onscroll = () => {
            window.addEventListener('scroll', this.handle, { passive: true });
        };
    };

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    };

    handleScroll = () => {
        const homeInViewPort = this.isInViewport(this.homePageRef);
        const serviceInViewPort = this.isInViewport(this.servicesPageRef);
        const RealisationsInViewPort = this.isInViewport(this.realisationsPageRef);
        const contactInViewPort = this.isInViewport(this.contactPageRef);

        if (homeInViewPort && !this.state.navItems[0].active) {
            this.updateActive(0, true);
        } else if (!homeInViewPort && this.state.navItems[0].active) {
            this.updateActive(0, false);
        }

        if (serviceInViewPort && !this.state.navItems[1].active) {
            this.updateActive(1, true);
        } else if (!serviceInViewPort && this.state.navItems[1].active) {
            this.updateActive(1, false);
        }

        if (RealisationsInViewPort && !this.state.navItems[2].active) {
            this.updateActive(2, true);
        } else if (!RealisationsInViewPort && this.state.navItems[2].active) {
            this.updateActive(2, false);
        }

        if (contactInViewPort && !this.state.navItems[3].active) {
            this.updateActive(3, true);
        } else if (!contactInViewPort && this.state.navItems[3].active) {
            this.updateActive(3, false);
        }
    };

    updateActive = (index, active) => {
        const navItems = [...this.state.navItems];
        const navItem = { ...navItems[index], active: active };
        navItems[index] = navItem;

        this.setState({
            navItems: navItems,
        });
    };

    isInViewport = (elem) => {
        elem = elem.current;
        var bounding = elem.getBoundingClientRect();

        return bounding.top <= (window.innerHeight || document.documentElement.clientHeight) / 2 && bounding.bottom >= (window.innerHeight || document.documentElement.clientHeight) / 2;
    };

    handleNavigationClicked = (e, name) => {
        e.preventDefault();

        switch (name) {
            case 'Home':
                this.homePageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                break;
            case 'Onze diensten':
                this.servicesPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                break;
            case 'Realisaties':
                this.realisationsPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                break;
            case 'Contact':
                this.setState(
                    {
                        contactPageType: contactPageTypes.CONTACT,
                    },
                    () => {
                        this.contactPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                    }
                );
                break;
            case 'Quotation':
                this.setState(
                    {
                        contactPageType: contactPageTypes.QUOTATION,
                    },
                    () => {
                        this.contactPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                    }
                );
                break;
            default:
                this.homePageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                break;
        }
    };

    handleInvoiceClicked = (e) => {
        this.handleNavigationClicked(e, 'Quotation');
    };

    handleServiceClicked = (e) => {
        this.handleNavigationClicked(e, 'Onze diensten');
    };

    handleContactTypeChanged = (e) => {
        e.preventDefault();

        let type = null;

        switch (e.currentTarget.value) {
            case '1':
                type = contactPageTypes.CONTACT;
                break;
            case '2':
                type = contactPageTypes.QUOTATION;
                break;
            default:
                type = null;
        }

        this.setState({
            contactPageType: type,
        });
    };

    handleDateChanged = (date) => {
        this.setState({
            contact: {
                ...this.state.contact,
                date: date,
            },
        });
    };

    render() {
        return (
            <div className='h-100'>
                <Navigation items={this.state.navItems} onNavClick={this.handleNavigationClicked} />
                <HomePage reference={this.homePageRef} onServiceClick={this.handleServiceClicked} onInvoiceClick={this.handleInvoiceClicked} />
                <ServicesPage reference={this.servicesPageRef} />
                <RealisationsPage reference={this.realisationsPageRef} />
                <ContactPage
                    reference={this.contactPageRef}
                    type={this.state.contactPageType}
                    contact={this.state.contact}
                    onTypeChange={this.handleContactTypeChanged}
                    onDateChange={this.handleDateChanged}
                />
            </div>
        );
    }
}

export default App;
