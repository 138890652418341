import React from 'react';

import './RealisationsPage.scss';

import Realisation from '../Realisations/Realisation';

import dans from '../../assets/images/dans.jpg';
import dansSmall from '../../assets/images/dans-480.jpg';
import dansMedium from '../../assets/images/dans-1080.jpg';
import extra from '../../assets/images/extra.jpg';
import extraSmall from '../../assets/images/extra-480.jpg';
import extraMedium from '../../assets/images/extra-1080.jpg';
import breakout from '../../assets/images/breakout.jpg';
import flokk from '../../assets/images/flokk.jpg';
/*import flokkSmall from '../../assets/images/flokk-480.jpg';
import flokkMedium from '../../assets/images/flokk-1080.jpg';*/
import kinepolis from '../../assets/images/kinepolis.jpg';
import kinepolisSmall from '../../assets/images/kinepolis-480.jpg';
import kinepolisMedium from '../../assets/images/kinepolis-1080.jpg';
/*import sfi from '../../assets/images/sfi.jpg';
import sfiSmall from '../../assets/images/sfi-480.jpg';
import sfiMedium from '../../assets/images/sfi-1080.jpg';*/
import schoolfeest from '../../assets/images/schoolfeest.jpg';
import communie from '../../assets/images/communie.jpg';
import communieSmall from '../../assets/images/spot-480.jpg';
import communieMedium from '../../assets/images/spot-1080.jpg';
import toneel from '../../assets/images/toneel.jpg';
import toneelSmall from '../../assets/images/toneel-480.jpg';
import toneelMedium from '../../assets/images/toneel-1080.jpg';

const RealisationsPage = (props) => {
    return (
        <div className='component realisations d-flex justify-content-center' ref={props.reference}>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='block-title'>
                        <div className='line-before d-none d-md-table-cell'> </div>
                        <h1 style={{ width: '150px' }}>
                            <span>Realisaties</span>
                        </h1>
                        <div className='line-after d-none d-md-table-cell'> </div>
                    </div>
                </div>
                <div className='row'>
                    <Realisation
                        img={dans}
                        srcSet={`${dansSmall} 480w, ${dansMedium} 1080w`}
                        sizes='(max-width: 992px) 480px, 1080px'
                        width={1080}
                        height={810}
                        name={'Dansvoorstelling'}
                        description={'Wij voorzien alles voor uw dansvoorstellingen. Van professioneel advies tot de live voorstelling.'}
                    />

                    <Realisation
                        img={extra}
                        srcSet={`${extraSmall} 480w, ${extraMedium} 1080w`}
                        sizes='(max-width: 992px) 480px, 1080px'
                        width={1080}
                        height={806}
                        name="Party's"
                        description='Wilt u net iets meer voor uw feest dan bent u bij ons aan het goeie adres.'
                    />

                    <Realisation
                        img={flokk}
                        /*srcSet={`${factsSmall} 480w, ${factsMedium} 1080w`}*/
                        width={1919}
                        height={1440}
                        name={'Flokk'}
                        description={'Flokk NV rekende op pandasound om hun symposium in de sporthall van Gent te voorzien van audiovisuele support.'}
                    />

                    <Realisation
                        img={kinepolis}
                        srcSet={`${kinepolisSmall} 480w, ${kinepolisMedium} 1080w`}
                        sizes='(max-width: 992px) 480px, 1080px'
                        width={1080}
                        height={810}
                        name={'Kinepolis'}
                        description={'Het is bijna kerst en dan mag kerstdecoratie zeker niet ontbreken, zo koos ook Kinepolis Kortrijk voor Pandasound.'}
                    />
                </div>
                <div className='row my-md-4 mt-0 d-none d-md-flex '>
                    <Realisation
                        img={schoolfeest}
                        /*srcSet={`${sfiSmall} 480w, ${sfiMedium} 1080w`}*/
                        width={1919}
                        height={1440}
                        name={'Schoolfeesten'}
                        description={'Bij het SFI in Poperinge voorzien we al enkele jaren het schooltoneel van licht en geluid. De kinderen zijn in de wolken.'}
                    />

                    <Realisation
                        img={communie}
                        srcSet={`${communieSmall} 480w, ${communieMedium} 1080w`}
                        width={1080}
                        height={810}
                        sizes='(max-width: 992px) 480px, 1080px'
                        name={'Communie'}
                        description={'Ook in de kerk zijn we geen vreemde, we voorzagen al enkele communies van het correcte geluid.'}
                    />

                    <Realisation
                        img={toneel}
                        srcSet={`${toneelSmall} 480w, ${toneelMedium} 1080w`}
                        width={1080}
                        height={810}
                        sizes='(max-width: 992px) 480px, 1080px'
                        name={'Toneel'}
                        description={'We hebben al jaren lang ervaring bij het voorzien van belichting en geluid voor toneelstukken.'}
                    />

                    <Realisation
                        img={breakout}
                        /*srcSet={`${extraSmall} 480w, ${extraMedium} 1080w`}*/
                        width={1919}
                        height={1440}
                        name={'Lanparty'}
                        description={'Op Breakout lanparty voorzien we al jaren de sfeerbelichting en projectie.'}
                    />
                </div>
            </div>
        </div>
    );
};

export default RealisationsPage;
