import React, { Component } from 'react';

import NavItem from './NavItem';
import './Navigation.scss';

class Navigation extends Component {
    state = {
        showNavigation: false,
    };

    toggleNavigation = () => {
        this.setState({
            showNavigation: !this.state.showNavigation,
        });
    };

    render = () => {
        let navItems = null;
        let navbarClasses = ['navbar', 'navbar-expand-lg', 'justify-content-end', 'fixed-top', 'py-sm-3'];
        let navItemClasses = ['collapse', 'navbar-collapse', 'justify-content-end'];
        let activeId = 0;
        let rgba = '';

        // Render all the navigation items
        if (this.props.items) {
            navItems = this.props.items.map((item, i) => {
                activeId = item.active ? item.id : activeId;
                return <NavItem key={i} {...item} onNavClick={this.props.onNavClick} />;
            });
        }

        if (this.state.showNavigation) {
            navItemClasses.push('show');
        }

        switch (activeId) {
            case 1:
                navbarClasses.push('menu-default');
                break;
            case 2:
                navbarClasses.push('menu-light-green');
                break;
            case 3:
                navbarClasses.push('menu-light-green');
                break;
            case 4:
                navbarClasses.push('menu-dark-green');
                break;
            default:
                break;
        }

        return (
            <nav className={navbarClasses.join(' ')} style={{ background: rgba }}>
                <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarNav' aria-controls='navbarNav' aria-expanded='false' aria-label='Toggle navigation'>
                    <i className='fas fa-bars text-white' onClick={this.toggleNavigation}></i>
                </button>
                <div className={navItemClasses.join(' ')} id='navbarNav'>
                    <ul className='navbar-nav'>{navItems}</ul>
                </div>
            </nav>
        );
    };
}

export default Navigation;
