import React from 'react';

import './ServicesPage.scss';

import lights from '../../assets/images/lights-header.jpg';
import sound from '../../assets/images/sound-header.jpg';
import video from '../../assets/images/video-header.jpg';

const servicesPage = (props) => {
    return (
        <div className='component services d-flex justify-content-center' ref={props.reference}>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='block-title'>
                        <div className='line-before d-none d-md-table-cell'> </div>
                        <h1 className='text-white'>
                            <span>Onze diensten</span>
                        </h1>
                        <div className='line-after d-none d-md-table-cell'> </div>
                    </div>
                </div>

                <div className='row justify-content-md-center'>
                    <div className='col-xs-12 col-md-3 text-center m-4'>
                        <div>
                            <img src={lights} className='img-fluid' alt='Header voor de licht sectie' />
                        </div>
                        <div className='col-12 bg-white service-content'>
                            <h4 className='mb-3'>Licht</h4>
                            <span className='text-muted'>
                                Een correcte samenstelling en de perfecte hoek zijn maar enkele aspecten van een goede belichting. Wij adviseren u graag om het beste resultaat te bekomen.
                            </span>
                        </div>
                    </div>
                    <div className='col-xs-12 col-md-3 text-center m-4'>
                        <div>
                            <img src={sound} className='img-fluid' alt='Header voor de licht sectie' />
                        </div>
                        <div className='col-12 col bg-white service-content'>
                            <h4 className='mb-3'>Geluid</h4>
                            <span className='text-muted'>
                                Een glashelder geluid, afgestemd op de omgeving, is van essentieel belang op een event. Ons materiaal is van de hoogste kwaliteit zodat uw geluid correct over komt.
                            </span>
                        </div>
                    </div>
                    <div className='col-xs-12 col-md-3 text-center m-4'>
                        <div>
                            <img src={video} className='img-fluid' alt='Header voor de licht sectie' />
                        </div>
                        <div className='col-12 bg-white service-content'>
                            <h4 className='mb-3'>Video</h4>
                            <span className='text-muted'>
                                Bedrijfsfilm, reclame of presentatie. Zorg ervoor dat uw boodschap blijft hangen bij het publiek. Een beeld zegt nu éénmaal meer dan dan 1000 woorden.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default servicesPage;
